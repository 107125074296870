import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
  

  <path d="M4718 9487 c-99 -30 -178 -77 -255 -153 -35 -34 -63 -64 -63 -67 0
-4 -14 -28 -30 -54 -17 -26 -40 -72 -51 -103 -22 -57 -299 -848 -303 -865 -2
-5 -6 -17 -9 -25 -12 -28 -104 -285 -109 -305 -3 -11 -34 -99 -68 -195 -34
-96 -79 -224 -100 -285 -130 -376 -248 -713 -387 -1105 -30 -85 -61 -173 -68
-195 -8 -22 -43 -125 -80 -230 -37 -104 -102 -291 -145 -415 -43 -124 -97
-277 -120 -340 -50 -138 -113 -319 -145 -415 -13 -38 -49 -142 -80 -230 -31
-88 -74 -209 -95 -270 -89 -257 -112 -321 -120 -340 -5 -11 -21 -58 -37 -105
-23 -73 -27 -102 -28 -200 0 -71 6 -130 14 -155 23 -68 64 -149 88 -175 13
-14 23 -28 23 -32 0 -23 156 -138 216 -159 21 -8 41 -17 44 -20 12 -14 158
-39 230 -39 75 0 230 25 240 39 3 4 21 13 40 21 111 45 238 167 299 287 24 47
33 52 53 26 49 -66 334 -214 458 -238 19 -4 40 -11 45 -15 14 -11 169 -38 290
-51 263 -28 666 41 947 162 98 42 255 118 273 132 5 4 49 34 97 65 47 31 97
66 110 77 13 11 41 33 63 49 22 16 45 34 51 40 5 6 42 42 81 79 l70 69 113
-36 c61 -20 158 -43 214 -51 194 -28 527 -12 670 31 209 64 348 121 478 196
59 35 165 105 184 123 7 5 49 41 95 79 268 222 497 592 609 981 52 178 34 315
-56 425 -30 36 -131 98 -192 116 -70 22 -229 24 -287 5 -169 -56 -271 -161
-321 -331 -56 -190 -216 -432 -364 -551 -141 -114 -279 -168 -450 -175 -116
-5 -207 5 -203 22 1 5 71 214 156 464 85 250 205 606 266 790 62 184 117 344
122 355 4 11 42 121 83 245 126 377 393 1168 399 1177 4 7 22 85 47 198 43
201 32 434 -34 670 -15 56 -53 155 -66 170 -4 5 -11 21 -15 35 -9 29 -94 167
-139 225 -39 51 -179 190 -231 231 -254 196 -573 317 -908 344 -298 24 -571
-48 -821 -216 -48 -32 -66 -35 -66 -11 -1 16 -78 98 -112 120 -133 83 -248
118 -413 123 -104 4 -130 1 -197 -19z m1362 -1048 c69 -11 202 -67 235 -100
89 -86 95 -93 127 -150 44 -78 62 -154 62 -263 1 -99 -5 -162 -16 -181 -3 -6
-9 -26 -13 -45 -5 -30 -45 -162 -62 -210 -12 -32 -247 -727 -319 -940 -41
-124 -79 -234 -84 -245 -5 -11 -23 -65 -40 -120 -17 -55 -36 -109 -41 -120 -8
-18 -68 -196 -74 -220 -1 -5 -5 -17 -8 -25 -4 -8 -20 -55 -37 -105 -17 -49
-34 -99 -39 -110 -5 -11 -23 -67 -42 -125 -18 -58 -36 -109 -40 -115 -4 -5
-10 -25 -14 -42 -3 -18 -11 -35 -16 -39 -6 -3 -40 14 -77 38 -132 87 -212 131
-352 191 -59 26 -281 85 -345 92 -33 4 -71 9 -85 11 -52 9 -319 5 -403 -6 -49
-7 -90 -10 -93 -8 -2 3 17 63 42 134 25 71 61 176 81 234 19 58 40 119 47 135
7 17 25 66 40 110 15 44 31 89 36 100 5 11 20 54 33 95 14 41 49 140 77 220
28 80 67 192 86 250 20 58 40 114 45 125 5 11 58 162 118 335 168 485 225 601
388 794 191 226 509 350 783 305z m-1271 -3744 c127 -30 281 -109 385 -196 73
-61 116 -102 116 -110 0 -4 -39 -48 -87 -97 -238 -241 -551 -353 -829 -296
-98 20 -164 55 -219 116 -52 58 -69 115 -63 206 12 168 162 320 368 373 83 22
244 24 329 4z"/>
<path d="M8363 7738 c-68 -4 -178 -19 -245 -33 -68 -15 -129 -27 -137 -27 -17
-2 -10 -44 25 -160 19 -63 32 -88 43 -88 9 0 50 9 91 20 113 31 344 45 489 31
290 -27 591 -139 816 -303 42 -30 180 -152 224 -197 151 -157 307 -416 367
-610 l19 -62 38 5 c21 4 46 11 55 16 9 5 46 16 81 25 36 9 67 18 69 20 15 15
-128 346 -201 466 -40 65 -120 173 -173 234 -97 111 -129 144 -214 215 -379
319 -862 480 -1347 448z"/>
<path d="M8320 7200 c-30 -5 -66 -11 -80 -14 -96 -21 -120 -29 -120 -41 0 -11
38 -157 57 -217 4 -13 10 -15 27 -9 50 20 211 33 328 28 242 -10 437 -80 632
-227 58 -43 151 -140 202 -211 72 -98 102 -152 145 -260 31 -77 44 -91 69 -80
3 2 12 4 20 6 44 10 175 47 181 50 13 9 -73 228 -113 287 -10 14 -18 29 -18
32 0 8 -48 82 -71 111 -170 208 -288 307 -484 405 -135 67 -167 79 -315 115
-83 20 -137 27 -255 30 -82 2 -175 0 -205 -5z"/>
<path d="M8320 6657 c-30 -7 -55 -16 -55 -22 0 -10 57 -228 61 -233 1 -2 36 2
78 8 250 38 512 -110 602 -340 21 -53 30 -61 58 -50 12 5 59 18 106 31 47 12
88 25 93 29 18 16 -58 177 -127 267 -103 135 -276 254 -437 299 -72 20 -307
27 -379 11z"/>
<path d="M8411 6039 c-41 -16 -81 -50 -105 -88 -108 -171 86 -385 264 -292
132 69 159 225 56 327 -57 57 -148 80 -215 53z"/>
<path d="M4919 2484 c-9 -3 -21 -16 -28 -30 -28 -62 68 -106 105 -48 16 24 16
27 -3 52 -20 27 -42 34 -74 26z"/>
<path d="M5587 2483 c-4 -3 -7 -138 -7 -300 l0 -293 50 0 50 0 0 144 c0 131 2
146 21 170 19 24 27 27 78 24 54 -3 56 -4 68 -38 7 -20 12 -93 12 -167 l1
-133 45 0 45 0 0 163 c0 196 -8 221 -82 251 -42 17 -52 18 -87 6 -22 -7 -50
-24 -62 -36 -13 -13 -26 -24 -31 -24 -4 0 -8 54 -8 120 l0 120 -43 0 c-24 0
-47 -3 -50 -7z"/>
<path d="M2618 2444 l-27 -5 0 -274 0 -274 56 -7 c72 -9 205 2 267 22 98 33
156 127 156 252 0 136 -57 232 -158 267 -47 16 -240 28 -294 19z m280 -111
c49 -37 64 -74 64 -166 -1 -93 -17 -139 -60 -171 -20 -16 -43 -20 -114 -20
l-89 -1 0 188 c0 103 0 190 1 195 0 4 38 5 85 1 64 -4 92 -10 113 -26z"/>
<path d="M4450 2170 l0 -279 33 -6 c17 -3 97 -5 177 -3 l145 3 0 40 0 40 -128
0 c-78 0 -127 4 -127 10 0 6 -1 113 -3 239 l-2 229 -47 3 -48 2 0 -278z"/>
<path d="M3671 2384 c-7 -9 -11 -28 -9 -45 4 -27 2 -29 -28 -29 -18 0 -36 -4
-39 -10 -3 -5 0 -10 7 -10 10 0 10 -2 1 -8 -22 -14 -8 -47 20 -47 14 -1 29 -4
33 -7 3 -4 6 -57 5 -117 -3 -180 22 -222 134 -229 76 -4 99 10 87 53 -8 28
-12 30 -49 27 -66 -5 -73 9 -73 149 l0 119 60 0 60 0 0 35 c0 29 -4 36 -25 41
-14 3 -35 4 -46 1 -37 -10 -49 2 -49 49 l0 44 -37 0 c-21 -1 -44 -8 -52 -16z"/>
<path d="M6112 2387 c-6 -6 -12 -28 -14 -47 -2 -22 -8 -35 -18 -36 -43 -3 -46
-5 -44 -31 1 -16 2 -32 3 -36 0 -5 13 -7 29 -5 l27 3 5 -135 c4 -107 9 -142
23 -163 30 -47 61 -60 127 -55 74 5 87 16 71 56 -11 27 -15 29 -55 25 -28 -4
-45 -1 -53 8 -6 8 -13 65 -15 134 l-3 120 63 3 62 3 0 40 0 40 -52 -1 c-29 -1
-59 -1 -65 -1 -9 1 -13 17 -13 46 0 44 0 45 -34 45 -18 0 -38 -6 -44 -13z"/>
<path d="M5393 2329 c-11 -29 -14 -30 -50 -22 -111 22 -195 -22 -220 -116 -7
-27 31 -109 54 -118 15 -6 18 -33 3 -33 -18 0 -50 -40 -50 -63 0 -13 9 -33 21
-45 22 -25 18 -42 -11 -42 -19 0 -60 -48 -60 -70 0 -6 7 -25 16 -41 24 -47 82
-69 185 -69 137 0 209 47 209 136 0 87 -41 115 -173 119 -93 3 -111 10 -105
41 2 15 17 21 73 29 82 12 129 37 150 81 20 43 19 73 -5 112 l-21 33 31 29
c33 31 38 55 14 64 -32 12 -50 5 -61 -25z m-69 -90 c42 -20 46 -108 6 -129
-22 -12 -70 -13 -86 -2 -39 26 -40 100 -1 127 26 18 47 19 81 4z m66 -375 c22
-25 1 -67 -40 -81 -36 -13 -120 -9 -151 7 -26 14 -31 40 -14 66 14 22 22 24
104 24 68 0 92 -4 101 -16z"/>
<path d="M7406 2329 c-6 -31 -7 -31 -49 -24 -127 25 -217 -29 -217 -130 0 -39
20 -82 47 -98 20 -13 15 -32 -12 -47 -36 -19 -42 -55 -16 -91 23 -30 21 -49
-5 -49 -26 0 -54 -38 -54 -75 0 -66 71 -106 187 -106 129 0 192 33 213 111 14
52 1 91 -41 121 -23 16 -48 21 -125 24 -91 3 -113 10 -114 37 0 21 36 38 82
38 27 0 66 8 85 17 68 32 95 122 52 177 l-21 26 31 29 c34 32 40 56 15 65 -35
13 -51 6 -58 -25z m-62 -92 c29 -21 36 -77 14 -109 -24 -35 -85 -39 -112 -9
-18 20 -24 80 -11 101 17 28 81 38 109 17z m64 -394 c5 -47 -26 -67 -107 -67
-73 -1 -103 14 -114 54 -9 33 23 49 103 52 97 4 114 -2 118 -39z"/>
<path d="M4900 2107 l0 -212 47 0 46 1 -1 179 c-1 99 -1 195 -2 213 0 31 -1
32 -45 32 l-45 0 0 -213z"/>
<path d="M6836 2310 c-16 -5 -44 -24 -62 -41 -34 -33 -38 -32 -46 16 -2 16
-12 21 -46 23 l-42 3 0 -215 0 -216 38 0 c20 0 42 4 49 8 7 5 11 52 11 144 1
110 4 142 17 162 29 44 117 54 147 18 9 -10 14 -63 16 -169 l4 -153 24 -1 c13
-1 32 -2 42 -3 16 -1 17 14 17 174 0 165 -1 177 -22 206 -12 17 -35 34 -50 37
-16 4 -37 9 -48 11 -11 3 -33 1 -49 -4z"/>
<path d="M3250 2288 c-69 -34 -75 -42 -54 -77 l18 -30 30 22 c21 15 49 23 92
25 56 4 63 2 74 -18 20 -37 -9 -66 -77 -80 -94 -19 -137 -42 -157 -85 -16 -32
-16 -43 -5 -82 15 -55 54 -83 117 -83 51 0 79 12 109 48 l22 26 11 -26 c28
-60 130 -66 130 -8 0 21 -6 29 -25 34 -14 4 -25 12 -25 19 -2 215 -6 253 -25
281 -39 59 -152 75 -235 34z m161 -244 c-3 -59 -14 -80 -43 -90 -65 -20 -108
3 -108 58 0 32 27 53 83 63 20 4 40 11 43 17 15 23 28 -2 25 -48z"/>
<path d="M4044 2292 c-45 -19 -74 -39 -74 -54 0 -5 7 -20 16 -33 l16 -24 38
25 c31 21 47 25 96 22 57 -3 59 -4 62 -32 2 -16 -1 -33 -6 -38 -5 -5 -52 -22
-103 -36 -81 -24 -96 -31 -116 -61 -13 -19 -23 -46 -23 -60 1 -39 32 -94 60
-108 55 -28 146 -10 173 34 11 18 37 17 37 -2 0 -24 45 -47 87 -43 35 3 38 6
41 36 3 26 0 32 -17 32 -31 0 -36 18 -32 122 4 131 -15 190 -69 218 -48 25
-129 25 -186 2z m156 -252 c-8 -66 -31 -90 -85 -90 -48 0 -67 16 -67 56 0 35
24 57 80 70 80 20 79 20 72 -36z"/>
<path d="M6440 2003 c-42 -17 -55 -75 -22 -99 40 -30 102 -5 102 42 0 23 -35
64 -53 63 -7 0 -19 -3 -27 -6z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
